<template>
  <div>
    <festival-form :show.sync="festivalFormStatus" :festival="editingFestival"></festival-form>
    <v-row>
      <v-col sm="12" class="text-right">
        <v-btn icon outlined color="primary" @click="festivalFormStatus = true"><v-icon v-html="icons.mdiPlus" /></v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title><h4>Festivals</h4></v-card-title>
          <v-card-text>
            <v-row>
              <v-col md="12">
                <v-text-field
                  dense
                  outlined
                  clearable
                  label="Search"
                  :prepend-inner-icon="icons.mdiMagnify"
                  class="app-bar-search flex-grow-0"
                  :value="selectedFilters.search"
                  @input="debounceSearch"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="festivals"
                  disable-sort
                  :hide-default-footer="!festivals.length"
                  :items-per-page="15"
                  :page="pagination.page"
                  :server-items-length="pagination.total"
                  :footer-props="{
                    'items-per-page-options': [15],
                    'items-per-page-text': '',
                  }"
                  @update:page="handleUpdatePage"
                  :loading="loading"
                >
                  <template v-slot:item.action="{ item }">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn small icon class="me-n3 mt-n1" v-bind="attrs" v-on="on">
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="editFestival(item.id)">
                          <v-list-item-title>Edit</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="confirmDeletion(item.id)" class="error">
                          <v-list-item-title class="white--text">Delete</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog persistent max-width="400" :value="deleteForm">
      <v-card>
        <v-card-title class="d-block w-full text-center">Confirm</v-card-title>
        <v-card-text class="text-center">Are you sure you want to delete this record?</v-card-text>
        <v-card-actions class="mt-2 text-center d-block">
          <v-btn color="secondary" outlined @click="deleteForm = false">No</v-btn>
          <v-btn color="primary" @click="deleteFestival">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import { mdiCheckBold, mdiDotsVertical, mdiPlus, mdiMagnify } from '@mdi/js';
  import FestivalForm from "@/components/festival/Form";
  import _ from "lodash";

  export default {
    data() {
      return {
        festivals: [],
        queryParams: {
          page: 1,
          search: null,
        },
        pagination: {
          page: 1,
          total: 0,
        },
        headers: [
          {text: 'Name', value: 'name'},
          {text: 'Actions', value: 'action', align: 'end'},
        ],
        icons: {
          mdiMagnify,
          mdiCheckBold,
          mdiDotsVertical,
          mdiPlus
        },
        editingFestival: null,
        festivalFormStatus: false,
        deletingFestivalID: null,
        deleteForm: false,
        loading: false
      }
    },
    computed: {
      selectedFilters() {

        return  {
          search: this.queryParams.search,
        };
      }
    },
    components: {
      FestivalForm,
    },
    watch: {
      queryParams: {
        deep: true,
        async handler() {

          if (this.$router.currentRoute.fullPath !== this.$router.resolve({name: this.$router.currentRoute.name, query: this.queryParams}).route.fullPath) this.$router.replace({query: this.queryParams});
          await this.fetchFestivals(this.queryParams);
        }
      },
      festivalFormStatus() {

        if (this.festivalFormStatus === false) {

          this.fetchFestivals(this.queryParams);
          this.editingFestival = null;
        }
      },
      deleteForm() {

        if (this.deleteForm === false) this.deletingFestivalID = null;
      }
    },
    async created() {

      if (Object.entries(this.$router.currentRoute.query).length > 0) {

        this.queryParams = {
          page: this.$router.currentRoute.query.page && this.$router.currentRoute.query.page !== 'undefined' ? parseInt(this.$router.currentRoute.query.page) : 1,
          search: this.$router.currentRoute.query.search && this.$router.currentRoute.query.search !== 'undefined' ? this.$router.currentRoute.query.search : null,
        };
      } else {

        this.fetchFestivals(this.queryParams);
      }
    },
    methods: {
      fetchFestivals(params) {

        this.loading = true;
        axiosBackend.get('/festivals', {params})
          .then((response) => {

            this.festivals = response.data.festivals;
            this.pagination.total = response.data.meta.total;
            this.pagination.page = response.data.meta.current_page;
            this.loading = false;
          });
      },
      debounceSearch: _.debounce(function (value) {

        this.updateFilter(value, 'search')
      }, 500),
      updateFilter(value, field) {

        this.queryParams[field] = value;
        this.queryParams.page = 1;
      },
      handleUpdatePage(page) {

        this.queryParams.page = page;
      },
      editFestival(id) {

        this.editingFestival = this.festivals.find(item => item.id === id);
        this.festivalFormStatus = true;
      },
      confirmDeletion(id) {

        this.deletingFestivalID = id;
        this.deleteForm = true;
      },
      deleteFestival() {

        this.loading = true;
        axiosBackend.delete('/festivals/' + this.deletingFestivalID)
        .then(() => {

          this.fetchFestivals(this.queryParams);
          this.deleteForm = false;
          this.loading = false;
        });
      }
    }
  }
</script>

<style scoped>

</style>
